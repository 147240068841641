import React from "react";

const FormElem = (props) => {
  let errorMessage = false;
  if (props.errors) {
    props.errors.forEach((error) => {
      if (error.error_id === "-ve-" + props.name) {
        errorMessage = error.message;
      }
    });
  }

  return (
    <div className="form-control-holder" type={props.type}>
      {errorMessage && (
        <div className="alert alert-danger p-10">{errorMessage}</div>
      )}
      {props.type !== "textarea" ? (
        <input
          className={
            "form-control mb-15" + (errorMessage ? " form-control-error" : "")
          }
          name={props.name}
          id={props.name}
          type={props.type}
          value={props.value}
          onChange={props.onChange}
          data-lpignore="true"
          placeholder={props.children}
        />
      ) : (
        <textarea
          className="form-control"
          name={props.name}
          id={props.name}
          value={props.value}
          onChange={props.onChange}
          placeholder={props.children}
        />
      )}
    </div>
  );
};

export default class ContactForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      Name: "",
      Email: "",
      Address: "",
      Phone: "",
      Resume: "",
      Message: "",
      status: null,
      statusMessage: null,
      errors: null,
      dark: !!props.dark,
      career: !!props.career,
    };
  }

  handleInputChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    // Get the form element, action & method, as well as body.
    const formElem = event.target,
      { action, method } = formElem,
      body = new FormData(formElem);

    // Send the request to Contact Form 7.
    fetch(action, {
      method,
      body,
    })
      // Convert response to json.
      .then((response) => response.json())
      .then((response) => {
        console.log(response);

        // response.invalid fields is our errors, otherwise, set this to null.
        const errors = response.invalid_fields ? response.invalid_fields : null;

        // Set state after submitting form.
        this.setState({
          status: response.status,
          statusMessage: response.message,
          errors: errors,
        });

        // Reset needed values if email was sent.
        if (this.state.status === "mail_sent") {
          this.setState({
            Name: "",
            Email: "",
            Address: "",
            Phone: "",
            Resume: "",
            Message: "",
            errors: null,
          });
        }
      })
      .catch((error) => {
        // Uh oh, there was an error.
        console.log(error);

        // This shouldn't happen, but handle it, just in case.
        this.setState({
          status: "error",
          statusMessage:
            "There was an unknown error. Please try contacting us directly instead.",
          errors: null,
        });
      });
  };

  render() {
    return (
      <form
        action={`${
          process.env.GATSBY_WP_URL
        }/wp-json/contact-form-7/v1/contact-forms/${
          this.state.career ? 765 : 5
        }/feedback`}
        method="post"
        onSubmit={this.handleSubmit}
      >
        {this.state.status && (
          <div
            className={
              "alert " +
              (this.state.status === "mail_sent"
                ? "alert-success"
                : "alert-danger")
            }
          >
            {this.state.statusMessage}
          </div>
        )}
        <FormElem
          name="Name"
          type="text"
          value={this.state.Name}
          onChange={this.handleInputChange}
          index={0}
          errors={this.state.errors}
        >
          Name*
        </FormElem>
        <FormElem
          name="Email"
          type="email"
          value={this.state.Email}
          onChange={this.handleInputChange}
          index={1}
          errors={this.state.errors}
        >
          Email*
        </FormElem>
        {this.state.career ? (
          <>
            <FormElem
              name="Phone"
              type="tel"
              value={this.state.Phone}
              onChange={this.handleInputChange}
              index={2}
              errors={this.state.errors}
            >
              Phone
            </FormElem>
            <p>
              <label htmlFor="Resume">Resume</label>
            </p>
            <FormElem
              name="Resume"
              type="file"
              value={this.state.Resume}
              onChange={this.handleInputChange}
              index={3}
              errors={this.state.errors}
            >
              Resume
            </FormElem>
          </>
        ) : (
          <FormElem
            name="Address"
            type="text"
            value={this.state.Address}
            onChange={this.handleInputChange}
            index={2}
            errors={this.state.errors}
          >
            Address
          </FormElem>
        )}
        <FormElem
          name="Message"
          type="textarea"
          value={this.state.Message}
          onChange={this.handleInputChange}
          index={this.state.career ? 4 : 3}
          errors={this.state.errors}
        >
          Message
        </FormElem>
        <button
          className={`btn ${
            this.state.dark ? "btn-primary" : "btn-dark"
          } mt-35`}
          type="submit"
        >
          Submit
        </button>
      </form>
    );
  }
}
