import React, { useEffect } from "react";
import { Link } from "gatsby";
import replaceSiteUrl from "./replace-site-url";

// Is this an internal link and we need to use Gatsby's Link, or an external link and we need use a regular a tag?

// This part was taken from here: https://www.designcise.com/web/tutorial/how-to-check-if-a-string-url-refers-to-an-external-link-using-javascript
const IsExternalLink = (url) => {
  return useEffect(() => {
    let tmp = document.createElement("a");
    tmp.href = url;
    return tmp.host !== window.location.host;
  });
};

// I wrote this part with a little help from Stack Overflow.
const LinkHandler = ({ href, children, ...props }) => {
  href = replaceSiteUrl(href);
  if (!IsExternalLink(href)) {
    return (
      <Link to={href} {...props}>
        {children}
      </Link>
    );
  } else {
    return (
      <a href={href} {...props}>
        {children}
      </a>
    );
  }
};

export default LinkHandler;
