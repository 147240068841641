import React, { useEffect, useRef } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import formatWpMenu from "../../utils/format-wp-menu";
import PhoneIcon from "../../svgs/phone.svg";
import LinkHandler from "../../utils/link-handler";
import replaceSiteUrl from "../../utils/replace-site-url";
import { Helmet } from "react-helmet";

const Header = () => {
  const navbarCollapse = useRef(null),
    navbarToggler = useRef(null),
    showNavClass = "show-nav";

  const toggleMobileNav = (elem, action) => {
    if (action === "remove") {
      elem.classList.remove(showNavClass);
    } else {
      elem.classList.toggle(showNavClass);
    }
  };

  const toggleAllMobileNav = (action) => {
    toggleMobileNav(navbarCollapse.current, action);
    toggleMobileNav(navbarToggler.current, action);
  };

  const handleNavToggle = (e) => {
    e.preventDefault();

    toggleAllMobileNav("toggle");
  };

  const handleBodyClick = (e) => {
    if (e.target.closest("#header")) return;

    toggleAllMobileNav("remove");
  };

  const handleWindowScroll = () => {
    toggleAllMobileNav("remove");
  };

  useEffect(() => {
    const navbarCollapseElem = navbarCollapse.current;

    navbarCollapseElem.addEventListener("show.bs.collapse", handleNavToggle);

    document.body.addEventListener("click", handleBodyClick);

    window.addEventListener("scroll", handleWindowScroll);

    return () => {
      navbarCollapseElem.removeEventListener(
        "show.bs.collapse",
        handleNavToggle
      );

      document.body.removeEventListener("click", handleBodyClick);

      window.removeEventListener("scroll", handleWindowScroll);
    };
  });

  const {
    wp: {
      acfOptionsThemeSettings: { themeSettings: header },
    },
    wpMenu: {
      menuItems: { nodes: navLinks },
    },
  } = useStaticQuery(
    graphql`
      query Header {
        wp {
          acfOptionsThemeSettings {
            themeSettings {
              logo {
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 290)
                  }
                }
                altText
              }
              phoneNumber
              customCssOverrides
            }
          }
        }
        wpMenu(slug: { eq: "main-menu" }) {
          menuItems {
            nodes {
              id
              label
              path
              parentId
              target
            }
          }
        }
      }
    `
  );

  const logo = getImage(header.logo.localFile),
    formattedNavLinks = formatWpMenu(navLinks),
    phoneLink = "tel:" + header.phoneNumber.replace(/\D/g, "");

  return (
    <header id="header" className="sticky-top">
      <Helmet>
        {header.customCssOverrides && (
          <style>{header.customCssOverrides}</style>
        )}
      </Helmet>
      <div className="header-phone bg-white d-xxl-none">
        <a href={phoneLink}>
          <PhoneIcon className="icon-phone fill-gray" /> {header.phoneNumber}
        </a>
      </div>
      <nav id="nav" className="navbar navbar-expand-xxl navbar-dark bg-dark">
        <div className="container">
          <Link className="navbar-brand" to="/">
            <GatsbyImage
              image={logo}
              alt={header.logo.altText}
              loading="eager"
            />
          </Link>

          <button
            className="navbar-toggler d-flex d-xxl-none"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#nav-navbar"
            aria-controls="nav-navbar"
            aria-expanded="false"
            aria-label="Toggle navigation"
            ref={navbarToggler}
          >
            <span className="navbar-toggler-icon">
              <span className="navbar-toggler-bar"> </span>
              <span className="navbar-toggler-bar"> </span>
              <span className="navbar-toggler-bar"> </span>
            </span>
            <span className="navbar-menu-text">Menu</span>
          </button>
          <div
            className="collapse navbar-collapse flex-grow-0"
            id="nav-navbar"
            ref={navbarCollapse}
          >
            <ul className="navbar-nav">
              {formattedNavLinks.map((link) =>
                link.children.length === 0 ? (
                  <li key={link.id} className="nav-item">
                    <LinkHandler
                      className="nav-link"
                      aria-current="page"
                      href={replaceSiteUrl(link.path)}
                      target={link.target}
                      onClick={() => toggleAllMobileNav("remove")}
                    >
                      {link.label}
                    </LinkHandler>
                  </li>
                ) : (
                  <li key={link.id} className="nav-item dropdown">
                    <LinkHandler
                      className="nav-link dropdown-toggle"
                      href={replaceSiteUrl(link.path)}
                      onClick={() => toggleAllMobileNav("remove")}
                    >
                      {link.label}
                    </LinkHandler>
                    <ul className="dropdown-menu">
                      {link.children.map((childLink) => (
                        <li key={childLink.id}>
                          <LinkHandler
                            className="dropdown-item"
                            href={replaceSiteUrl(childLink.path)}
                          >
                            {childLink.label}
                          </LinkHandler>
                        </li>
                      ))}
                    </ul>
                  </li>
                )
              )}
              <li className="nav-item d-none d-xxl-block">
                <a className="nav-link" href={phoneLink}>
                  <PhoneIcon className="icon-phone" /> {header.phoneNumber}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
