import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import formatWpMenu from "../../utils/format-wp-menu";
import ContactForm from "../utils/contact-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Footer = () => {
  const {
    wp: {
      acfOptionsThemeSettings: { themeSettings: footer },
    },
    wpMenu: {
      menuItems: { nodes: socialLinks },
    },
  } = useStaticQuery(
    graphql`
      query Footer {
        wp {
          acfOptionsThemeSettings {
            themeSettings {
              footerLogo {
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 258)
                  }
                }
                altText
              }
              footerContent
              copyright
              contactTitle
              mapTitle
              map {
                localFile {
                  url
                }
                altText
              }
              mapHtml
            }
          }
        }
        wpMenu(slug: { eq: "social-menu" }) {
          menuItems {
            nodes {
              id
              label
              path
            }
          }
        }
      }
    `
  );

  const footerLogo = getImage(footer.footerLogo.localFile),
    formattedSocialLinks = formatWpMenu(socialLinks),
    copyrightYear = new Date().getFullYear();

  return (
    <footer id="footer">
      <div className="container">
        <div className="grid grid-footer">
          <div className="footer-main bg-white g-col-2 g-col-xl-1">
            <div className="footer-logo mb-45">
              <Link to="/">
                <GatsbyImage
                  image={footerLogo}
                  alt={footer.footerLogo.altText}
                />
              </Link>
            </div>
            <div className="grid grid-footer-content">
              <div className="footer-content-left g-col-2 g-col-sm-1">
                <div
                  className="content footer-content text-secondary mb-10"
                  dangerouslySetInnerHTML={{ __html: footer.footerContent }}
                />
                <ul className="footer-social-icons d-flex mb-20">
                  {formattedSocialLinks.map((link) => (
                    <li key={link.id}>
                      <a href={link.path} target="_blank" rel="noreferrer">
                        <FontAwesomeIcon icon={["fab", link.label]} />
                      </a>
                    </li>
                  ))}
                </ul>
                <div
                  className="footer-copyright text-gray d-none d-xl-block"
                  dangerouslySetInnerHTML={{
                    __html: footer.copyright.replace(
                      "{{ year }}",
                      copyrightYear
                    ),
                  }}
                />
              </div>
              <div className="footer-content-right g-col-2 g-col-sm-1">
                <div
                  className="h-inherit h-reset h4 text-secondary mb-15"
                  dangerouslySetInnerHTML={{ __html: footer.contactTitle }}
                />
                <ContactForm />
              </div>
            </div>
          </div>
          <div className="footer-secondary bg-primary text-white g-col-2 g-col-xl-1">
            <div
              className="map-title h5 h-inherit h-reset mb-35 mb-xl-0"
              dangerouslySetInnerHTML={{ __html: footer.mapTitle }}
            />
            <div className="footer-map">
              {footer.mapHtml ? (
                <div
                  className="footer-map-code"
                  dangerouslySetInnerHTML={{ __html: footer.mapHtml }}
                />
              ) : (
                <img
                  className="d-block"
                  src={footer.map.localFile.url}
                  alt={footer.map.altText}
                />
              )}
            </div>
            <div
              className="footer-copyright text-gray d-block d-xl-none mt-70 mt-xl-0"
              dangerouslySetInnerHTML={{
                __html: footer.copyright.replace("{{ year }}", copyrightYear),
              }}
            />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
