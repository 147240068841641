import React, { useEffect } from "react";
import "../../styles/style.scss";
import Header from "./header";
import Footer from "./footer";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";

library.add(fab);

export default function Layout({ children }) {
  useEffect(() => {
    import("bootstrap");

    // Does device have touch?
    if('ontouchstart' in document.documentElement) {
      document.body.classList.add('touch-device');
    }
  });
  return (
    <>
      <Header />
      <main id="main">{children}</main>
      <Footer />
    </>
  );
}
